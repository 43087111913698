export { default as ArrowRight } from './arrow_right.svg';
export { default as ArrowheadDown } from './arrowhead_down.svg';
export { default as ArrowheadLeft } from './arrowhead_left.svg';
export { default as ArrowheadRight } from './arrowhead_right.svg';
export { default as BankId } from './bankid.svg';
export { default as BoxArrow } from './box_arrow.svg';
export { default as Cart } from './cart.svg';
export { default as Close } from './close.svg';
export { default as CompassArrow } from './compass_arrow.svg';
export { default as Filter } from './filter.svg';
export { default as House } from './house.svg';
export { default as Info } from './information.svg';
export { default as Leaf } from './leaf.svg';
export { default as MagnifyingGlass } from './magnifying-glass.svg';
export { default as Menu } from './menu.svg';
export { default as Minus } from './minus.svg';
export { default as Phone } from './phone.svg';
export { default as Pill } from './pill.svg';
export { default as Plus } from './plus.svg';
export { default as Profile } from './profile.svg';
export { default as SpeechBubble } from './speech-bubble.svg';
export { default as Tick } from './tick.svg';
export { default as Truck } from './truck.svg';
export { default as AlertInfo } from './information_alert.svg';
export { default as AlertError } from './error_alert.svg';
export { default as AlertSuccess } from './success_alert.svg';
export { default as AlertWarning } from './warning_alert.svg';
export { default as PangPris } from './pangpris.svg';
export { default as Union } from './union.svg';
export { default as Instagram } from './instagram.svg';
export { default as Facebook } from './facebook.svg';
export { default as LinkedIn } from './linkedin.svg';
export { default as YouTube } from './youtube.svg';
export { default as Person } from './person.svg';
export { default as Pen } from './pen.svg';

import React, { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { container } from 'tsyringe';
import { AuthSessionTimer as SessionTimer } from '~/contexts/auth/AuthSessionTimer';
import { useAuthState } from '~/contexts/auth/auth.provider';
import { HttpStatusCodes } from '~/services/middleware/HttpStatusCodes';
import type { StatusEventDispatcher } from '~/services/middleware/StatusEventDispatcher';
import { ChatEventTopic } from '../chat/chat-event-topic';
import { useChatMediator } from '../chat/use-chat-mediator';
import { touch } from './auth-session.api';
import { IdleTimer } from './auth.idle-timer.component';

const SESSION_TIMEOUT = process.env.NEXT_PUBLIC_AUTH_SESSION_TIMEOUT as unknown as number;

const AuthSessionTimer: React.FC<{ authInstance: StatusEventDispatcher }> = ({ authInstance }) => {
  const [startIdleTimer, setStartIdleTimer] = useState<boolean>(false);
  const { subscribe, unsubscribe } = useChatMediator();

  const sessionTimer = container.resolve(SessionTimer);
  sessionTimer.handleTimeout = () => {
    setStartIdleTimer(true);
  };

  const { setHasNotSession } = useAuthState();

  useEffect(() => {
    const subscriber = {
      status: HttpStatusCodes.Success,
      act: () => {
        setStartIdleTimer(false);
        sessionTimer.restart();
      },
    };
    authInstance.subscribeToStatus(subscriber);
    return () => {
      // clean up subscribers and stop the timer
      authInstance.unsubscribe(subscriber);
      sessionTimer.stop();
    };
  }, [sessionTimer, authInstance]);

  useEffect(() => {
    const restartSessionTimer = throttle(
      () => {
        sessionTimer.restart();
        touch();
      },
      SESSION_TIMEOUT / 5,
      { trailing: true, leading: true },
    );

    subscribe(ChatEventTopic.GiosgChatMessageRecieved, restartSessionTimer);
    subscribe(ChatEventTopic.GiosgChatMessageSent, restartSessionTimer);
    subscribe(ChatEventTopic.GiosgMaximized, restartSessionTimer);

    return () => {
      unsubscribe(ChatEventTopic.GiosgChatMessageRecieved, restartSessionTimer);
      unsubscribe(ChatEventTopic.GiosgChatMessageSent, restartSessionTimer);
      unsubscribe(ChatEventTopic.GiosgMaximized, restartSessionTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (startIdleTimer) {
    return (
      <IdleTimer
        touch={() => {
          touch();
          setStartIdleTimer(false);
        }}
        setHasNotSession={setHasNotSession}
      />
    );
  }
  return null;
};

export const AuthSessionTimerAuthenticationChecked: React.FC<{
  authInstance: StatusEventDispatcher;
}> = ({ authInstance }) => {
  const { authenticated } = useAuthState();
  if (authenticated) {
    return <AuthSessionTimer authInstance={authInstance} />;
  }
  return null;
};

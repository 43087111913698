import { ChatEventTopic } from './chat-event-topic';
import { useChatEventActions, useChatEventActionsStore } from './use-chat-event-actions-store';

type useChatMediatorReturn = {
  notify: (topic: ChatEventTopic, onCompleted?: () => void, event?: Event | undefined) => void;
  subscribe: (topic: ChatEventTopic, callback: () => void) => void;
  unsubscribe: (topic: ChatEventTopic, callback: () => void) => void;
  registerEventTopics: () => void;
  removeEventTopics: () => void;
};

const eventTopics = [
  ChatEventTopic.TeneoContinueWithLiveChat,
  ChatEventTopic.TeneoInitialized,
  ChatEventTopic.TeneoMinimize,
  ChatEventTopic.TeneoClosed,
  ChatEventTopic.ConsentChanged,
  ChatEventTopic.ContactUsModalClose,
];

export const useChatMediator = (): useChatMediatorReturn => {
  const { add, create, remove } = useChatEventActions();
  const {
    registeredActionsState: { registeredActions },
  } = useChatEventActionsStore();

  const notify = (topic: ChatEventTopic, onCompleted?: () => void) => {
    registeredActions.get(topic)?.forEach((fn) => fn(onCompleted));
  };

  const subscribe = (topic: ChatEventTopic, callback: () => void) => {
    if (!registeredActions.get(topic)) {
      create(topic, callback);
    } else {
      add(topic, callback);
    }
  };

  const unsubscribe = (topic: ChatEventTopic, callback: () => void) => {
    remove(topic, callback);
  };

  // Save references for callback for removing listeners
  const eventTopicCallback = eventTopics.reduce(
    (o, topic) => ({ ...o, [topic]: () => notify(topic) }),
    {},
  ) as Record<ChatEventTopic, () => void>;

  const registerEventTopics = () => {
    eventTopics.forEach((topic) => {
      window.addEventListener(topic, eventTopicCallback[topic]);
    });
  };

  const removeEventTopics = () => {
    eventTopics.forEach((topic) => {
      window.removeEventListener(topic, eventTopicCallback[topic]);
    });
  };

  return {
    subscribe,
    unsubscribe,
    notify,
    registerEventTopics,
    removeEventTopics,
  };
};

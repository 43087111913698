type SendEvent<Data = unknown> = {
  event: string;
  data?: Data;
  search_term?: string;
  click_text?: string;
};
type SendEcommerce = {
  event: string;
  ecommerce: unknown;
};

export type CartEventProductType = {
  name?: string;
  articleCode?: string;
  webPrice?: number;
  brands?: string[];
  isSensitive?: boolean;
};

const sendPageView = (url: string) => {
  interface PageEventProps {
    event: string;
    page: string;
  }
  const pageEvent: PageEventProps = {
    event: 'pageview',
    page: url,
  };
  if (window && window.dataLayer) {
    window.dataLayer.push(pageEvent);
  }
};

export const sendEvent = <Event extends SendEvent>(event: Event) => {
  if (window && window.dataLayer) {
    window.dataLayer.push(event);
  }
};

export const sendEcommerce = (event: SendEcommerce) => {  
  if (window && window.dataLayer) {
    // The ecommerce field needs to be reset before every push to the dataLayer in order for the ecommerce data to not
    // get merged with previous events' data.
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
  }
};

/**
 * @deprecated
 * This is not a hook, import the events as functions instead.
 * e.g
 * ```typescript
 * import { sendWhateverEvent } from 'this-file';
 * ...
 * sendWhatEverEvent(yourData);
 * ```
 * instead of
 * ```typescript
 * import { useGtm } from 'this-file';
 * ...
 * const { sendWhateverEvent } = useGtm();
 * sendWhatEverEvent(yourData);
 * ```
 */
export const useGtm = () => ({
  sendPageView,
  sendEvent,
});

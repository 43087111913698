export enum ChatEventTopic {
  // Giosg events
  GiosgReady = 'aph_chat_giosgReady',
  GiosgMinimized = 'aph_chat_giosgMinimized',
  GiosgMaximized = 'aph_chat_giosgMaximized',
  GiosgChatEnded = 'aph_chat_giosgChatEnded',
  GiosgChatStarted = 'aph_chat_giosgChatStarted',
  GiosgChatMessageRecieved = 'aph_chat_giosgMessageRecieved',
  GiosgChatMessageSent = 'aph_chat_giosgMessageSent',
  GiosgOpen = 'aph_chat_openGiosg',

  // Teneo internal events (our own set of events)
  TeneoReady = 'aph_chat_teneoReady',
  TeneoReset = 'aph_chat_teneoReset',
  TeneoOpen = 'aph_chat_teneoOpen',
  TeneoFetchChatHistory = 'aph_chat_teneoFetchChatHistory',
  // Teneo external events (Teneos set of events)
  TeneoInitialized = 'twcInitialized',
  TeneoMinimize = 'twcMinimized',
  TeneoContinueWithLiveChat = 'twcStartLivechat',
  TeneoClosed = 'twcCloseButtonClicked',

  // Chat modal events
  ContactUsModalClose = 'aph_chat_contactUsModalClose',
  ContactUsModalOpen = 'aph_chat_contactUsModalOpen',

  // OneTrust events (OneTrust set of events)
  ConsentChanged = 'consent.onetrust',

  // General chat events
  ChatPlay = 'aph_chat_chatPlay',
}

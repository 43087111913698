import { useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { logger } from '~/logging';
import { useChatStore } from '../chat/chat-store/use-chat-store';
import { minimizeCurrentChatModal } from '../chat/event-handlers';
import { ConfirmLoggedInSession } from '../confirm-logged-in-session/confirm-logged-in-session.component';

export const IdleTimer: React.FC<{ setHasNotSession: () => void; touch: () => void }> = ({
  setHasNotSession,
  touch,
}) => {
  const idleTimeout = process.env.NEXT_PUBLIC_AUTH_IDLE_TIMEOUT as unknown as number;
  const [open, setOpen] = useState<boolean>(false);
  const { currentChatModal, isMaximized } = useChatStore((state) => ({
    currentChatModal: state.chatState.currentChatModal,
    isMaximized: state.chatState.isMaximized,
  }));

  const { start } = useIdleTimer({
    timeout: idleTimeout,
    timers: workerTimers,
    promptBeforeIdle: 120000,
    throttle: 500,
    onIdle: () => {
      logger.debug('useIdleTimer executed onIdle');
      setOpen(false);
      setHasNotSession();
    },
    onPrompt: () => {
      setOpen(true);
    },
  });

  useEffect(() => {
    if (open && isMaximized) {
      minimizeCurrentChatModal(currentChatModal);
    }
  }, [currentChatModal, isMaximized, open]);

  const handleLoggedInSession = () => {
    touch();
    start();
    setOpen(false);
  };

  const handleLogOut = () => {
    setOpen(false);
    setHasNotSession();
  };
  return (
    <ConfirmLoggedInSession
      onConfirm={handleLoggedInSession}
      onLogOut={handleLogOut}
      isModalOpen={open}
    />
  );
};

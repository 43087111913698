import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/system';
import { useGiosgActions } from '~/model/giosg/useGiosgStore';
import theme from '~/styles/theme';
import { ChatEventTopic } from './chat-event-topic';
import { VisibleModal } from './chat-store/chat-state';
import { useChatActions } from './chat-store/use-chat-store';
import { ContactUsFab } from './contact-us-fab/contact-us-fab.component';
import { ContactUsWidget } from './contact-us-widget/contact-us-widget.component';
import {
  fetchTeneoChatHistory,
  getHasAcceptedOneTrustByCategoryId,
  openGiosg,
  openTeneo,
  resetTeneo,
  showCookies,
} from './event-handlers';
import { useChatMediator } from './use-chat-mediator';
import { constants } from './utils/constants';

export const ChatContainer: React.FC = () => {
  // ChatMediator
  const { subscribe, unsubscribe, notify, registerEventTopics, removeEventTopics } =
    useChatMediator();
  const { getToken } = useGiosgActions();

  // ChatStore
  const {
    updateCurrentChatStateModal,
    setIsGiosgReady,
    setIsTeneoReady,
    setIsMaximized,
    getIsMaximized,
    setIsLivechatEnded,
    getIsLivechatEnded,
    getCurrentModal,
    getIsGiosgAndTeneoReady,
    resetState,
    setIsFunctionalCookiesAccepted,
    getIsFunctionalCookiesAccepted,
  } = useChatActions();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const router = useRouter();

  useEffect(() => {
    setIsGiosgReady(false);
    setIsTeneoReady(false);
    registerEventTopics();
    subscribe(ChatEventTopic.TeneoInitialized, onTwcInitialized);
    subscribe(ChatEventTopic.TeneoReady, onTeneoReady);
    subscribe(ChatEventTopic.GiosgReady, onGiosgReady);
    subscribe(ChatEventTopic.ChatPlay, onPlay);
    subscribe(ChatEventTopic.ContactUsModalOpen, onOpenContactUsModal);
    subscribe(ChatEventTopic.ContactUsModalClose, onCloseContactUsModal);
    subscribe(ChatEventTopic.TeneoOpen, onOpenTeneo);
    subscribe(ChatEventTopic.TeneoMinimize, onMinimizedTeneo);
    subscribe(ChatEventTopic.TeneoContinueWithLiveChat, onContinueWithLiveChat);
    subscribe(ChatEventTopic.TeneoFetchChatHistory, onFetchTeneoChatHistory);
    subscribe(ChatEventTopic.TeneoReset, onResetTeneo);
    subscribe(ChatEventTopic.GiosgOpen, onOpenGiosg);
    subscribe(ChatEventTopic.GiosgMaximized, onMaximizedGiosg);
    subscribe(ChatEventTopic.GiosgMinimized, onMinimizedGiosg);
    subscribe(ChatEventTopic.TeneoClosed, onTeneoClosed);
    subscribe(ChatEventTopic.GiosgChatEnded, onLivechatEnded);
    subscribe(ChatEventTopic.GiosgChatStarted, onLivechatStarted);
    subscribe(ChatEventTopic.ConsentChanged, onConsentChanged);

    return () => {
      setIsGiosgReady(false);
      setIsTeneoReady(false);
      removeEventTopics();
      unsubscribe(ChatEventTopic.TeneoInitialized, onTwcInitialized);
      unsubscribe(ChatEventTopic.TeneoReady, onTeneoReady);
      unsubscribe(ChatEventTopic.GiosgReady, onGiosgReady);
      unsubscribe(ChatEventTopic.ChatPlay, onPlay);
      unsubscribe(ChatEventTopic.ContactUsModalOpen, onOpenContactUsModal);
      unsubscribe(ChatEventTopic.ContactUsModalClose, onCloseContactUsModal);
      unsubscribe(ChatEventTopic.TeneoOpen, onOpenTeneo);
      unsubscribe(ChatEventTopic.TeneoMinimize, onMinimizedTeneo);
      unsubscribe(ChatEventTopic.TeneoContinueWithLiveChat, onContinueWithLiveChat);
      unsubscribe(ChatEventTopic.TeneoFetchChatHistory, onFetchTeneoChatHistory);
      unsubscribe(ChatEventTopic.TeneoReset, onResetTeneo);
      unsubscribe(ChatEventTopic.GiosgOpen, onOpenGiosg);
      unsubscribe(ChatEventTopic.GiosgMaximized, onMaximizedGiosg);
      unsubscribe(ChatEventTopic.GiosgMinimized, onMinimizedGiosg);
      unsubscribe(ChatEventTopic.TeneoClosed, onTeneoClosed);
      unsubscribe(ChatEventTopic.GiosgChatEnded, onLivechatEnded);
      unsubscribe(ChatEventTopic.GiosgChatStarted, onLivechatStarted);
      unsubscribe(ChatEventTopic.ConsentChanged, onConsentChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTwcInitialized = () => {
    notify(ChatEventTopic.TeneoReady);
  };

  const onTeneoReady = () => {
    setIsTeneoReady(true, () => notify(ChatEventTopic.ChatPlay));
  };

  const onGiosgReady = () => {
    setIsGiosgReady(true, () => notify(ChatEventTopic.ChatPlay));
  };

  const onPlay = () => {
    if (!getHasAcceptedOneTrustByCategoryId(constants.FUNCTIONAL_COOKIE_ID)) {
      if (getCurrentModal() === VisibleModal.ContactUsModal && getIsMaximized()) {
        notify(ChatEventTopic.ContactUsModalOpen);
      }
      return;
    }

    if (!getIsGiosgAndTeneoReady() || !getIsMaximized()) {
      return;
    }

    const modalActions = {
      [VisibleModal.ContactUsModal]: () => notify(ChatEventTopic.ContactUsModalOpen),
      [VisibleModal.GiosgChat]: () => notify(ChatEventTopic.GiosgOpen),
      [VisibleModal.TeneoChat]: () => notify(ChatEventTopic.TeneoOpen),
    };

    const currentModal = getCurrentModal();
    if (modalActions[currentModal]) {
      modalActions[currentModal]();
    }
  };

  const toggleWidget = () => {
    setIsMaximized(!getIsMaximized(), () => notify(ChatEventTopic.ChatPlay));
  };

  const onOpenContactUsModal = () => {
    updateCurrentChatStateModal(VisibleModal.ContactUsModal);
  };

  const onCloseContactUsModal = () => {
    setIsMaximized(false);
  };

  const onOpenTeneo = () => {
    updateCurrentChatStateModal(VisibleModal.TeneoChat, () => openTeneo());
  };

  const onResetTeneo = (onCompleted?: () => void) => {
    resetTeneo(onCompleted);
  };

  const onFetchTeneoChatHistory = (onCompleted?: () => void) => {
    fetchTeneoChatHistory(onCompleted);
  };

  const onConsentChanged = () => {
    const currentIsFunctionalCookiesAccepted = getIsFunctionalCookiesAccepted();
    const isFunctionalCookiesAccepted = getHasAcceptedOneTrustByCategoryId(
      constants.FUNCTIONAL_COOKIE_ID,
    );

    if (currentIsFunctionalCookiesAccepted !== isFunctionalCookiesAccepted) {
      setIsFunctionalCookiesAccepted(isFunctionalCookiesAccepted);
    }

    if (
      !isFunctionalCookiesAccepted &&
      isFunctionalCookiesAccepted !== currentIsFunctionalCookiesAccepted
    ) {
      resetState();
      router.reload();
    }
  };

  const onMinimizedGiosg = () => {
    if (getIsLivechatEnded()) {
      updateCurrentChatStateModal(VisibleModal.ContactUsModal);
    }
    setIsMaximized(false);
  };

  const onMaximizedGiosg = () => {
    setIsMaximized(true);
  };

  const onMinimizedTeneo = () => {
    setIsMaximized(false);
  };

  const onTeneoClosed = () => {
    updateCurrentChatStateModal(VisibleModal.ContactUsModal, () => setIsMaximized(false));
  };

  const onOpenGiosg = () => {
    updateCurrentChatStateModal(VisibleModal.GiosgChat, () => openGiosg(getToken));
  };

  const onContinueWithLiveChat = async () => {
    window.dataLayer.push({
      event: 'chat_live',
      contact_subject: '',
    });
    notify(ChatEventTopic.TeneoFetchChatHistory, async () => {
      notify(ChatEventTopic.TeneoReset);
      notify(ChatEventTopic.GiosgOpen);
    });
  };

  const startChat = () => {
    notify(ChatEventTopic.ContactUsModalClose);
    notify(ChatEventTopic.TeneoOpen);
  };

  const onLivechatEnded = () => {
    setIsLivechatEnded(true);
  };

  const onLivechatStarted = () => {
    setIsLivechatEnded(false);
  };

  return (
    <>
      <ContactUsWidget
        onToggle={toggleWidget}
        onStartChat={startChat}
        isMobile={isMobile}
        isOpen={getIsMaximized() && getCurrentModal() === VisibleModal.ContactUsModal}
        hasAcceptedCookies={getHasAcceptedOneTrustByCategoryId(constants.FUNCTIONAL_COOKIE_ID)}
        onCookiesClick={showCookies}
      />
      <ContactUsFab onToggle={toggleWidget} isContactUsButtonVisible={getIsMaximized()} />
    </>
  );
};

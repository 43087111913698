import { container } from 'tsyringe';
import { logger } from '~/logging';
import type {
  CreateHistoryTokenRequestV4,
  GetTokenResponseV4,
} from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

export const createHistoryToken: (
  body: CreateHistoryTokenRequestV4,
) => Promise<GetTokenResponseV4> = async (body) => {
  const client = container.resolve(CustomerClient);
  const result = await client.createHistoryTokenV4(
    client.uuid,
    'client-admin',
    undefined,
    undefined,
    body,
  );
  logger.info(`getHistoryToken() was called, Correlation ID: ${client.uuid} `);
  return result;
};

import { useEffect } from 'react';
import { useAuthState } from '~/contexts/auth/auth.provider';
import { useGiosgActions, useGiosgStore } from '~/model/giosg/useGiosgStore';
import { ChatEventTopic } from '../common/chat/chat-event-topic';
import { useChatActions, useChatStore } from '../common/chat/chat-store/use-chat-store';
import { useChatMediator } from '../common/chat/use-chat-mediator';
import { isGiosgOnline } from '../common/chat/utils/is-giosg-online';

export const LiveChatPlugin = () => {
  const { giosgState } = useGiosgStore();
  const { getToken, resetToken } = useGiosgActions();
  const { authenticated } = useAuthState();
  const { notify, subscribe, unsubscribe } = useChatMediator();
  const { chatState } = useChatStore();
  const { resetModalState, getIsLivechatEnded } = useChatActions();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/plain';
    script.async = true;
    script.id = 'giosg_script';
    script.setAttribute('class', 'optanon-category-C0003');
    script.innerHTML =
      "(function(w, t, f) {var s='script',o='_giosg',h='https://service.giosg.com',e,n;e=t.createElement(s);e.async=1;e.src=h+'/live2/'+f;w[o]=w[o]||function(){(w[o]._e=w[o]._e||[]).push(arguments)};w[o]._c=f;w[o]._h=h;n=t.getElementsByTagName(s)[0];n.parentNode.insertBefore(e,n);})(window,document,'56db4c5e-e0db-11ec-8bb5-0242ac12000e');";

    document.body.appendChild(script);

    return () => {
      document.getElementById('giosg_script')?.remove();
    };
  }, []);

  // Notify our mediator when giosg is ready to be interacted with
  useEffect(() => {
    window.onGiosgInteractionEvent = (event: { type: string; interactionId: string }) =>
      new Promise((resolve) => {
        if (event.type === 'interactiondesigner:ready') {
          try {
            notify(ChatEventTopic.GiosgReady);
            window.isGiosgOnline = isGiosgOnline; // We need this because teneo uses this to validate if customer service is online or not
            resolve('Action done');
          } catch (e) {
            resolve('Action done');
          }
        } else {
          // We dont care about other events, just proceed
          resolve('Action done');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle the event of giosg is ready and the authentication is done(authenticated or not) - Everything is ready to do an evaluation
  useEffect(() => {
    if (authenticated === null || !chatState.isGiosgReady || typeof window._giosg === 'undefined') {
      return;
    }
    const retry = localStorage.getItem('giosg_sign_in_retry');
    if (authenticated === true && retry === 'true') {
      getToken(); // Trigger
      return;
    }

    if (!authenticated && retry === 'false') {
      // If the user is not signed in and retry is false - says that the user was previously logged in and that we should clear and close the chat
      window._giosg('chat', 'close', { clearHistory: true, hide: true });
      window._giosg('visitor', 'removeAll');
      localStorage.removeItem('giosg_sign_in_retry');
      resetToken();
      resetModalState();
      return;
    }

    if (!authenticated) {
      // If the user is not signed in and retry is either true or not existing, we just remove visitor data since this is anonomous
      window._giosg('visitor', 'remove', 'Personnummer');
      window._giosg('visitor', 'remove', 'username');
    }
  }, [authenticated, chatState.isGiosgReady, getToken, resetModalState, resetToken]);

  // Listen to changes of the token generated by our backend
  useEffect(() => {
    if (giosgState.token !== '' && !giosgState.error && typeof window._giosg !== 'undefined') {
      const algorithm = 'HS256';
      window._giosg('visitor', 'submit', giosgState.token, algorithm);
      localStorage.setItem('giosg_sign_in_retry', 'false');
    }
    if (giosgState.error) {
      localStorage.setItem('giosg_sign_in_retry', 'true');
    }
  }, [giosgState.error, giosgState.token]);

  // Listen when the user "minimizes" / "maximizes" the giosg client and listen on when the chat-session is ended
  useEffect(() => {
    const handleChatWindowClose = () => {
      notify(ChatEventTopic.GiosgMinimized);
    };

    const handleChatWindowOpen = () => {
      notify(ChatEventTopic.GiosgMaximized);
    };

    const handleMessageRecieve = () => {
      notify(ChatEventTopic.GiosgChatMessageRecieved);
    };

    const handleMessageSend = () => {
      notify(ChatEventTopic.GiosgChatMessageSent);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleActiveChatChanged = (activeChatEvent: any) => {
      // Listen to when customer service ends a chat session and act on that
      if ((activeChatEvent && activeChatEvent.is_ended) || activeChatEvent === null) {
        if (!getIsLivechatEnded()) {
          // activeChatEvent becomes null when the giosg client is minimized and customer service permanently ends the chat
          notify(ChatEventTopic.GiosgChatEnded);
          window._giosg('chat', 'close', { clearHistory: true, hide: true });
          window._giosg('visitor', 'removeAll');
          resetModalState();
          window.location.reload();
        }
        return;
      }

      if (activeChatEvent && !activeChatEvent.is_ended && getIsLivechatEnded()) {
        notify(ChatEventTopic.GiosgChatStarted);
      }
    };

    const handleGiosgReady = () => {
      window.giosg.on('chatwindow:close', handleChatWindowClose);
      window.giosg.on('chatwindow:open', handleChatWindowOpen);
      window.giosg.on('message:receive', handleMessageRecieve);
      window.giosg.on('message:send', handleMessageSend);
      window.giosg.on('active_chat:changed', handleActiveChatChanged);
    };

    subscribe(ChatEventTopic.GiosgReady, handleGiosgReady);

    return () => {
      window.giosg.off('chatwindow:close', handleChatWindowClose);
      window.giosg.off('chatwindow:open', handleChatWindowOpen);
      window.giosg.off('message:receive', handleMessageRecieve);
      window.giosg.off('message:send', handleMessageSend);
      window.giosg.off('active_chat:changed', handleActiveChatChanged);
      unsubscribe(ChatEventTopic.GiosgReady, handleGiosgReady);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
